import React from 'react';
import TextFieldIcon from '@material-ui/icons/TextFields';
import FilePickerIcon from '@material-ui/icons/AttachFileOutlined';
import FormSectionIcon from '@material-ui/icons/ViewColumnOutlined';
import SelectFieldIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import RadioGroupIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlined';
import TermsAndConditionsIcon from '@material-ui/icons/AssignmentOutlined';
import HorizontalLine from '@material-ui/icons/LineStyle';
import { ATTRIBUTES, QUESTIONS } from '../../../../../components/RxForm/constants';

export const TYPE_LAYOUT = 'layout';
export const TYPE_CONTROL = 'control';
export const TYPE_RENDERED_CONTROL = 'rendered-control';

export const CONTROL_DATE = 'date';
export const CONTROL_DROPDOWN = 'dropdown';
export const CONTROL_TEXT = 'text';
export const CONTROL_PARAGRAPH = 'paragraph';
export const CONTROL_RADIOS = 'radios';
export const CONTROL_CHECKBOXES = 'checkboxes';
export const CONTROL_FILE = 'file';
export const CONTROL_TERMS = 'terms';
export const CONTROL_EXISTING = 'existing';

export const LAYOUT_SECTION = 'section';
export const LAYOUT_DIVIDER = 'divider';
export const LAYOUT_COLUMN = 'column';

export const FORM_ELEMENTS = {
  [TYPE_LAYOUT]: [
    {
      name: 'Form Section',
      draggableType: TYPE_LAYOUT,
      system_type: LAYOUT_SECTION,
      accept: TYPE_CONTROL,
      icon: <FormSectionIcon />
    },
    {
      name: 'Section Break',
      draggableType: TYPE_LAYOUT,
      system_type: LAYOUT_DIVIDER,
      icon: <HorizontalLine />
    }
  ],

  [TYPE_CONTROL]: [
    {
      name: 'Text',
      draggableType: TYPE_CONTROL,
      system_type: CONTROL_TEXT,
      icon: <TextFieldIcon />
    },
    {
      name: 'Paragraph',
      draggableType: TYPE_CONTROL,
      system_type: CONTROL_PARAGRAPH,
      icon: <TextFieldIcon />
    },
    {
      name: 'Dropdown',
      draggableType: TYPE_CONTROL,
      system_type: CONTROL_DROPDOWN,
      icon: <SelectFieldIcon />
    },

    {
      name: 'Mult. Choice',
      draggableType: TYPE_CONTROL,
      system_type: CONTROL_RADIOS,
      icon: <RadioGroupIcon />
    },

    {
      name: 'Checkboxes',
      draggableType: TYPE_CONTROL,
      system_type: CONTROL_CHECKBOXES,
      icon: <CheckBoxIcon />
    },
    {
      name: 'File Upload',
      draggableType: TYPE_CONTROL,
      system_type: CONTROL_FILE,
      icon: <FilePickerIcon />
    },
    {
      name: 'Terms',
      draggableType: TYPE_CONTROL,
      system_type: CONTROL_TERMS,
      icon: <TermsAndConditionsIcon />
    }
  ]
};

export const TYPES_WITH_OPTIONS = [CONTROL_DROPDOWN, CONTROL_RADIOS, CONTROL_CHECKBOXES];

export const EDIT_MODE_FORM_BUILDER = 'formbuilder';
export const EDIT_MODE_ORDERINGS = 'orderings';

export const EVENT_ADD_COLUMN = 'add-column';
export const EVENT_DELETE_COLUMN = 'delete-column';
export const EVENT_DELETE_LAYOUT_ELEMENT = 'delete-layout-element';
export const EVENT_ADD_LAYOUT_ELEMENT = 'add-layout-element';
export const EVENT_ADD_CONTROL = 'add-control';
export const EVENT_DELETE_CONTROL = 'delete-control';
export const EVENT_UPDATE_CONTROL = 'update-control';
export const EVENT_MOVE_CONTROL = 'move-control';
export const EVENT_MOVE_LAYOUT = 'move-layout';

export const EXISTING_RX_FIELDS = {
  [ATTRIBUTES.FIRST_NAME]: QUESTIONS[ATTRIBUTES.FIRST_NAME],
  [ATTRIBUTES.LAST_NAME]: QUESTIONS[ATTRIBUTES.LAST_NAME],
  [ATTRIBUTES.CASE_NUMBER_TEXT]: QUESTIONS[ATTRIBUTES.CASE_NUMBER_TEXT],
  [ATTRIBUTES.INTRAORAL_SCAN_DATE]: QUESTIONS[ATTRIBUTES.INTRAORAL_SCAN_DATE],
  [ATTRIBUTES.DOCTOR]: QUESTIONS[ATTRIBUTES.DOCTOR],
  [ATTRIBUTES.TREATMENT_LOCATION]: QUESTIONS[ATTRIBUTES.TREATMENT_LOCATION],
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]: QUESTIONS[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT],
  [ATTRIBUTES.RUSH_CASE]: QUESTIONS[ATTRIBUTES.RUSH_CASE],
  [ATTRIBUTES.CUSTOM_SETUP]: QUESTIONS[ATTRIBUTES.CUSTOM_SETUP],
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: QUESTIONS[ATTRIBUTES.SCAN_SUBMISSION_METHOD],
  [ATTRIBUTES.INTRAORAL_PHOTOS]: QUESTIONS[ATTRIBUTES.INTRAORAL_PHOTOS],
  [ATTRIBUTES.ARCH_TYPE]: QUESTIONS[ATTRIBUTES.ARCH_TYPE],
  [ATTRIBUTES.TEETH]: QUESTIONS[ATTRIBUTES.TEETH],
  [ATTRIBUTES.EXPORT_TYPE]: QUESTIONS[ATTRIBUTES.EXPORT_TYPE],
  [ATTRIBUTES.PRINT_ASSIGNMENT]: QUESTIONS[ATTRIBUTES.PRINT_ASSIGNMENT],
  [ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT]: QUESTIONS[ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT],
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: QUESTIONS[ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT],
  [ATTRIBUTES.BRACKET_LIBRARIES]: QUESTIONS[ATTRIBUTES.BRACKET_LIBRARIES],
  [ATTRIBUTES.ARCH_WIRES]: QUESTIONS[ATTRIBUTES.ARCH_WIRES],
  [ATTRIBUTES.SHIPPING_LOCATION]: QUESTIONS[ATTRIBUTES.SHIPPING_LOCATION],
  [ATTRIBUTES.SHIPPING_METHOD]: QUESTIONS[ATTRIBUTES.SHIPPING_METHOD],
  [ATTRIBUTES.ROOT_INTEGRATION]: QUESTIONS[ATTRIBUTES.ROOT_INTEGRATION],
  [ATTRIBUTES.BONDING_DATE]: QUESTIONS[ATTRIBUTES.BONDING_DATE],
  [ATTRIBUTES.NOTES]: QUESTIONS[ATTRIBUTES.NOTES]
};

export const randomId = seed => {
  return `${seed}-${
    Math.random()
      .toString(36)
      .split('.')[1]
  }`;
};

export const fieldHasOptions = systemType => {
  return TYPES_WITH_OPTIONS.includes(systemType);
};
