import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center'
  },
  content: {
    '& > .MuiTextField-root': {
      marginBottom: theme.spacing(1.5)
    }
  }
}));

const FormDialog = ({
  open,
  setOpen,
  children,
  bodyText,
  titleText,
  handleClose,
  acceptButtonText,
  isAcceptButtonDisabled,
  fullWidth,
  showButtons
}) => {
  const handleCloseButton = async response => {
    if (setOpen) {
      setOpen(false);
    }
    await handleClose(response);
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        open={open}
        onClose={async () => {
          await handleCloseButton(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.title}>
          {titleText}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {bodyText && <DialogContentText>{bodyText}</DialogContentText>}
          {children}
        </DialogContent>
        {showButtons && (
          <DialogActions>
            <Button onClick={() => handleCloseButton(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleCloseButton(true)}
              color="primary"
              disabled={isAcceptButtonDisabled}
            >
              {acceptButtonText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  bodyText: PropTypes.string,
  titleText: PropTypes.string,
  acceptButtonText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  isAcceptButtonDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showButtons: PropTypes.bool
};

FormDialog.defaultProps = {
  bodyText: '',
  titleText: '',
  acceptButtonText: 'Accept',
  isAcceptButtonDisabled: false,
  fullWidth: false,
  setOpen: null,
  showButtons: true
};

export default FormDialog;
