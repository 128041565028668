import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    },
    '& .MuiAccordionSummary-content': {
      margin: 0
    },
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  accordionDetail: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  actionWrapper: {
    marginBottom: theme.spacing(-3)
  },
  accordionHeader: props => {
    let titleFontSize;
    let subheaderFontSize;
    let padding;

    switch (props.size) {
      case 'small':
        titleFontSize = '0.8rem';
        subheaderFontSize = '0.6rem';
        padding = 0;
        break;
      case 'normal':
        titleFontSize = '1rem';
        subheaderFontSize = '0.8rem';
        padding = theme.spacing(1);
        break;
      case 'large':
      default:
        titleFontSize = '1.5rem';
        subheaderFontSize = '1rem';
        padding = theme.spacing(2);
        break;
    }

    return {
      '& .MuiCardHeader-title': {
        fontSize: titleFontSize
      },
      '& .MuiCardHeader-subheader': {
        fontSize: subheaderFontSize
      },
      padding
    };
  }
}));

/**
 * Todo:
 * - Proptypes
 * - Style header title
 */

const Wrapper = ({ title, subTitle, children, size, ...props }) => {
  const location = useLocation();
  let defaultExpanded = false;
  const ref = useRef();
  const history = useHistory();

  if (location.hash) {
    const section = location.hash.slice(1);
    if (title.replace(' ', '-').toLowerCase() === section) {
      defaultExpanded = true;
    }
  }
  const [opened, setOpened] = useState(defaultExpanded);

  useEffect(() => {
    if (defaultExpanded && location.hash) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      history.replace(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExpanded]);

  const changeHandler = (event, isExpanded) => {
    if (!opened && isExpanded) {
      setOpened(true);
    }
  };

  const classes = useStyles({ size });
  return (
    <Accordion ref={ref} defaultExpanded={defaultExpanded} onChange={changeHandler} {...props}>
      <AccordionSummary
        variant="outlined"
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon fontSize={size === 'normal' ? 'inherit' : size} />}
      >
        <CardHeader title={title} subheader={subTitle} className={classes.accordionHeader} />
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>{opened && children}</AccordionDetails>
    </Accordion>
  );
};

Wrapper.defaultProps = {
  subTitle: null,
  size: 'large'
};

Wrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['large', 'normal', 'small'])
};

export const SubSectionActionWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.actionWrapper}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
SubSectionActionWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default Wrapper;
