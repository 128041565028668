import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

import dibsLogo from '../../assets/images/logo-2024.png';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  avatar: {
    margin: theme.spacing(1),
    height: 60
  },

  success: {
    marginTop: 25,
    textAlign: 'center'
  }
}));

function SignUpSuccess() {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.paper}>
      <img className={classes.avatar} src={dibsLogo} alt="Logo" />

      <Typography variant="h6" className={classes.success}>
        To finish the sign-up process, please click "VERIFY" in the email just sent to you.
      </Typography>

      <Typography className={classes.success}>
        This will automatically log you into your DIBS AI account to finish the registration
        process.
      </Typography>
    </Container>
  );
}

export default SignUpSuccess;
