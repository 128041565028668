import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { Divider, Tooltip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import PatientForm from './PatientForm';
import {
  COMPLETE_PATIENT_PREFIX,
  completePatientAction,
  DELETE_PATIENT_PREFIX,
  deletePatientAction,
  RESTORE_PATIENT_PREFIX,
  restorePatientAction,
  UPDATE_PATIENT_PREFIX,
  updatePatientAction,
  updatePatientRxFormAction,
  ARCHIVE_PATIENT_PREFIX,
  archivePatientAction,
  REVERSE_CASE_PREFIX,
  reverseCaseAction
} from '../../../reducers/patients';
import createLoadingSelector from '../../../selectors/loading';
import StatusTag from '../../../components/StatusTag';
import { MARK_AS_READY_RX_FORM_PREFIX, markAsReadyRxFormAction } from '../../../reducers/rxForms';
import { getPatientsHomeRedirectURL } from '../../../helpers';
import OrganizationInfo from '../../../components/OrganizationInfo';
import MakeHidden from '../../../components/MakeHidden';
import PatientOnHoldChip from '../../../components/Chips/PatientOnHoldChip';

const useStyles = makeStyles(theme => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  archivedInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  archivedReason: {
    cursor: 'pointer',
    height: 20,
    width: 20
  },
  patientOnHoldChip: {
    marginLeft: theme.spacing(1),
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'white'
  }
}));

const UpdatePatient = ({ patient }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isUpdating = useSelector(state => createLoadingSelector([UPDATE_PATIENT_PREFIX])(state));
  const isReversing = useSelector(state => createLoadingSelector([REVERSE_CASE_PREFIX])(state));
  const isDeleting = useSelector(state => createLoadingSelector([DELETE_PATIENT_PREFIX])(state));
  const isRestoring = useSelector(state => createLoadingSelector([RESTORE_PATIENT_PREFIX])(state));
  const isArchiving = useSelector(state => createLoadingSelector([ARCHIVE_PATIENT_PREFIX])(state));
  const CURRENT_USER = useSelector(state => state.auth.user);
  const isManagement = useSelector(state => state.auth.isManagement);
  const isCompleting = useSelector(state =>
    createLoadingSelector([COMPLETE_PATIENT_PREFIX])(state)
  );
  const isMarkingAsReady = useSelector(state =>
    createLoadingSelector([MARK_AS_READY_RX_FORM_PREFIX])(state)
  );

  const updatePatient = values => {
    dispatch(updatePatientAction(patient.id, values)).then(response => {
      if (!response) {
        return;
      }

      if (!get(history, 'location.state.route', false)) {
        history.goBack();
      } else {
        history.push(history.location.state.route);
      }
    });
    return true;
  };

  const deletePatient = params => {
    dispatch(deletePatientAction(patient.id, params)).then(response => {
      if (!response) return;
      history.push(getPatientsHomeRedirectURL(CURRENT_USER));
    });
    return true;
  };

  const restorePatient = () => {
    dispatch(restorePatientAction(patient.id)).then(response => {
      if (!response) return;
      const path = isManagement ? '/patients-page' : '/patients';
      history.push(path);
    });
    return true;
  };

  const onMarkAsReady = rxFormId => {
    dispatch(markAsReadyRxFormAction(rxFormId)).then(response => {
      if (response) {
        dispatch(updatePatientRxFormAction(patient.id, response));
      }
    });
  };

  const onComplete = () => {
    dispatch(completePatientAction(patient.id)).then(response => {
      if (response) {
        const path = isManagement ? '/patients-page' : '/patients';
        history.push(path);
      }
    });
  };

  const onReverseCase = () => {
    dispatch(reverseCaseAction(patient.id)).then(response => {
      if (!response) return;
      history.push(getPatientsHomeRedirectURL(CURRENT_USER));
    });
  };

  const archivePatient = params => {
    dispatch(archivePatientAction(patient.id, params)).then(response => {
      if (!response) return;
      history.push(getPatientsHomeRedirectURL(CURRENT_USER));
    });
    return true;
  };

  if (!patient) {
    return null;
  }

  return (
    <Card className={classes.form}>
      <MakeHidden profile="normal">
        <CardHeader
          title="Patient"
          subheader={
            <>
              <OrganizationInfo organization={patient.organization} />
              {patient.deleted_at && (
                <StatusTag
                  label={
                    <div className={classes.archivedInfo}>
                      <span>{`Archived${
                        patient.archived_by_name ? ` by ${patient.archived_by_name}` : ''
                      }`}</span>
                      {patient.archived_reason && (
                        <Tooltip title={patient.archived_reason}>
                          <InfoIcon className={classes.archivedReason} />
                        </Tooltip>
                      )}
                    </div>
                  }
                  color="red"
                />
              )}
              <PatientOnHoldChip patient={patient} className={classes.patientOnHoldChip}/>
            </>
          }
        />
      </MakeHidden>
      <MakeHidden profile="management">
        <CardHeader title="Patient" />
      </MakeHidden>
      <Divider />
      <PatientForm
        onSubmit={updatePatient}
        isLoading={isUpdating}
        patient={patient}
        isDeleting={isDeleting}
        onDelete={deletePatient}
        isRestoring={isRestoring}
        onRestore={restorePatient}
        isMarkingAsReady={isMarkingAsReady}
        onMarkAsReady={onMarkAsReady}
        onComplete={onComplete}
        onReverseCase={onReverseCase}
        isCompleting={isCompleting}
        isReversing={isReversing}
        isArchiving={isArchiving}
        onArchive={archivePatient}
      />
    </Card>
  );
};

UpdatePatient.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object
};

UpdatePatient.defaultProps = {
  patient: null
};

export default UpdatePatient;
