import React, { useEffect, useState } from 'react';
import { Card, CardContent, Container, Divider, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Div } from 'react-images/lib/primitives';
import { fetchFormBuilderAction } from '../../reducers/formBuilder';
import PreviewForm from '../Account/components/RxFormCustomization/PreviewForm';
import FormBuilderDialog from '../Account/components/RxFormCustomization/FormBuilder';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const FormBuilder = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openFormBuilder, setOpenFormBuilder] = useState(false);
  const layout = useSelector(state => state.formBuilder.layout);
  const formBuilder = useSelector(state => state.formBuilder.form);
  const defaultControls = useSelector(state => state.formBuilder.defaultControls);

  useEffect(() => {
    const params = { default: true };
    dispatch(fetchFormBuilderAction(params));
  }, [dispatch]);

  return (
    <Container maxWidth="md">
      <Card>
        <CardContent>
          <Div className={classes.header}>
            <Typography variant="h5">Customize Default RxForm</Typography>
            <Div>
              <Button variant="outlined" color="primary" onClick={() => setOpenFormBuilder(true)}>
                Edit Form
              </Button>
            </Div>
          </Div>
          <Divider className={classes.divider} />

          <PreviewForm layout={layout} />

          <FormBuilderDialog
            open={openFormBuilder}
            formBuilder={formBuilder}
            layout={layout}
            defaultControls={defaultControls}
            onClose={() => setOpenFormBuilder(false)}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default FormBuilder;
