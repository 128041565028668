import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  chip: {
    height: 20,
    fontsize: 10,
    borderRadius: 20,
    marginLeft: 1,
    marginRight: 1,
    '& span': {
      paddingLeft: 6,
      paddingRight: 6,
      fontWeight: 500
    }
  }
}));

const Markers = ({ markers }) => {
  const classes = useStyles();

  const symbols = [];
  markers.forEach(({ symbol, color }) => {
    const exists = symbols.find(s => s.symbol === symbol && s.color === color);
    if (!exists) {
      symbols.push({
        symbol,
        color
      });
    }
  });

  return (
    <>
      {symbols.map(({ symbol, color }) => (
        <Chip
          key={`${symbol}-${color}`}
          label={symbol}
          className={classes.chip}
          style={{
            backgroundColor: `#${color}`,
            color: 'white'
          }}
        />
      ))}
    </>
  );
};

Markers.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      field: PropTypes.number,
      option: PropTypes.number
    })
  ).isRequired
};

export default Markers;
