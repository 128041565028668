import React, { useRef, useState } from 'react';
import {
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Chip
} from '@material-ui/core';
import TrashIcon from '@material-ui/icons/DeleteRounded';
import AddIcon from '@material-ui/icons/AddCircleRounded';
import PropTypes from 'prop-types';
import { randomId } from '../../helpers';

const ManageOptions = ({ defaultValue, setDefaultValue, options, addOption, removeOption }) => {
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  const onAddOption = () => {
    if (!value) return;

    addOption({ id: randomId('option'), name: value });

    setValue(curr => '');

    inputRef.current.focus();
  };

  const onRemoveOption = option => {
    removeOption(option);
  };

  const onChange = e => {
    const { value } = e.target;
    setValue(curr => value);
  };

  const onKeyUp = e => {
    if (e.key === 'Enter') {
      onAddOption();
    }
  };

  return (
    <div className="fb-manage-options">
      <div className="add-form mt-2rem">
        <TextField
          inputRef={inputRef}
          label="Enter Option(s)"
          variant="outlined"
          name="label"
          required
          size="small"
          value={value}
          onKeyUp={onKeyUp}
          onChange={onChange}
          fullWidth
          helperText={
            document.activeElement === inputRef.current
              ? `Hit enter to add or press the add button`
              : ''
          }
        />

        <IconButton onClick={onAddOption} className="add-btn">
          <AddIcon color="primary" />
        </IconButton>
      </div>

      <TableContainer>
        <Table size="small">
          <TableBody>
            {options.map((option, index) => (
              <TableRow key={index}>
                <TableCell>
                  {option.name}
                  {option.name === defaultValue && (
                    <Chip size="small" label="default" className="ml-1rem" />
                  )}
                </TableCell>
                <TableCell align="left">
                  <IconButton size="small" onClick={() => onRemoveOption(option)}>
                    <TrashIcon color="secondary" />
                  </IconButton>
                </TableCell>
                <TableCell align="left">
                  {option.name === defaultValue ? (
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => setDefaultValue('')}
                    >
                      Remove Default
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => setDefaultValue(option.name)}
                    >
                      Set As Default
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

ManageOptions.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  setDefaultValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  addOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired
};

export default ManageOptions;
