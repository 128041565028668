import React, { createContext, useContext } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import {
  Card,
  CardActions,
  Divider,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import get from 'lodash/get';
import { produce } from 'immer';
import { useSelector } from 'react-redux';
import { replaceNullToEmpty } from '../../helpers';
import InputErrorMessage from '../InputErrorMessage';
import DatePicker from '../DatePicker';
import UploadRxFormFile from '../../pages/RxForms/components/UploadRxFormFile';
import {
  ARCHES,
  ASSIGNMENTS,
  ATTRIBUTES,
  EXPORTS,
  QUESTIONS,
  SCANNERS,
  VIEW_LABEL
} from './constants';
import {
  autoDetectStlTypes,
  decodeFormikValues,
  encodeFormikValues,
  getDefaultPreferenceTrayDesignAssignment,
  validateDicomUpload
} from './helpers';
import AssignmentField from './Fields/AssignmentField';
import RadioGroupField from './Fields/RadioGroupField';
import ScanSubmissionMethodField from './Fields/ScanSubmissionMethodField';
import InsertBracketsAssignmentField from './Fields/InsertBracketsAssignmentField';
import LocationField from './Fields/LocationField';
import ShippingMethodField from './Fields/ShippingMethodField';
import RootIntegrationField from './Fields/RootIntegrationField';
import ScannerField from './Fields/ScannerField';
import { createValidationSchema } from './validation';
import ExportTypeField from './Fields/ExportTypeField';
import RushCaseField from './Fields/RushCaseField';
import BracketLibrariesField from './Fields/BracketLibrariesField';
import DoctorSelectionField from './Fields/DoctorSelectionField';
import BondingDateField from './Fields/BondingDateField';
import ArchWiresField from './Fields/ArchWiresField';
import {
  FILE_TYPE_IDS,
  ACCEPTED_FILE_TYPES,
  ZIP_EXTRACTABLE_FILE_TYPES,
  UPLOAD_NOTES
} from '../../constants';
import TrayDesignAssignmentField from './Fields/TrayDesignAssignmentField';
import TextField from './Fields/Formik/TextField';
import NameField from '../Formik/NameField';
import RenderedControl from '../../pages/Account/components/RxFormCustomization/FormBuilder/controls/RenderedControl';
import { createLabel } from '../../pages/Account/components/RxFormCustomization/FormBuilder/controls/helpers';
import {
  CONTROL_FILE,
  LAYOUT_DIVIDER
} from '../../pages/Account/components/RxFormCustomization/FormBuilder/helpers';
import Teeth from './Teeth';
import CustomSetupField from './Fields/CustomSetupField';
import IntraoralTypeSwitch from '../../pages/RxForms/components/FileModifiers/IntraoralTypeSwitch';
import tags from '../../constants/tags';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 0
  },
  hidden: {
    display: 'none'
  },
  control: { padding: theme.spacing(0.5) },
  section: {
    marginBottom: theme.spacing(0.5)
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  }
}));

const Context = createContext({ only: [], hiddenFields: [] });

const GridField = ({ show, name, skipOnly, isCustomField, children, ...props }) => {
  const classes = useStyles();
  const { only, hiddenFields } = useContext(Context);

  if (
    !skipOnly &&
    !isCustomField &&
    ((only.length > 0 && only.indexOf(name) === -1) || hiddenFields.indexOf(name) !== -1)
  ) {
    return null;
  }

  const overrideProps = { ...props, xs: 12, md: 12, lg: 12 };

  return (
    <Grid item {...overrideProps} className={!show ? classes.hidden : classes.control}>
      {children}
    </Grid>
  );
};

GridField.defaultProps = {
  show: true,
  skipOnly: false,
  isCustomField: false
};

GridField.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  show: PropTypes.bool,
  skipOnly: PropTypes.bool,
  isCustomField: PropTypes.bool
};

const hasUpdatableFields = (section, only) => {
  return (
    section.children.filter(col => {
      return (
        col.children.filter(field => {
          return field.system_type !== 'existing' || only.indexOf(field.system_name) !== -1;
        }).length > 0
      );
    }).length > 0
  );
};

const RxForm = ({
  organization,
  title,
  description,
  preference,
  hiddenFields,
  thirdPartyPartnerName,
  onSubmit,
  isLoading,
  rxForm,
  availableLocations,
  only,
  actions,
  doctors,
  pricing,
  formStructure,
  newControls,
  saveForLaterEnabled,
  ...props
}) => {
  const classes = useStyles();

  const withDoctorSelection = doctors.enabled;
  const overrideOptions = {
    requireDoctor: withDoctorSelection
  };
  const isManagement = useSelector(state => state.auth.isManagement);
  const validationSchema = createValidationSchema(only, overrideOptions, newControls);

  const encodedFormikValues = encodeFormikValues(rxForm);
  const initialValues = replaceNullToEmpty(encodedFormikValues, ['bonding_date']);

  const renderFormBuilderField = ({
    id,
    system_type: systemType,
    system_name: name,
    props,
    rules,
    setFieldValue,
    setFieldTouched,
    values
  }) => {
    const isCustomField = systemType !== 'existing';

    if (systemType === CONTROL_FILE) {
      const label = createLabel(props, rules);

      return (
        <GridField key={name} name={name} isCustomField={isCustomField}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <UploadRxFormFile
              accept={ACCEPTED_FILE_TYPES[FILE_TYPE_IDS.other]}
              name={name}
              preferenceValue={[]}
              title={label}
              onChange={files => {
                setTimeout(() => setFieldValue(name, files));
                setFieldTouched(name, true, false);
              }}
              values={values[name]}
              label="Add Files"
              maxFiles={10}
              organization={organization}
              autoExtractCompressedFiles={null}
              existingFiles={values[name]}
              preventDuplicate
            />
            <ErrorMessage
              name={name}
              render={message => <InputErrorMessage>{message}</InputErrorMessage>}
            />
          </FormControl>
        </GridField>
      );
    }

    return (
      <GridField key={id} name={name} isCustomField={isCustomField}>
        <Field
          id={name}
          name={name}
          props={props}
          rules={rules}
          system_type={systemType}
          component={RenderedControl}
        />
      </GridField>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={data => {
        const decodedValues = decodeFormikValues(data);
        return onSubmit(decodedValues);
      }}
    >
      {({
        submitForm,
        isValid,
        status,
        dirty,
        values,
        setFieldValue,
        isSubmitting,
        setFieldTouched
      }) => {
        const decodedValues = decodeFormikValues(values);
        const assignment = decodedValues[ATTRIBUTES.PRINT_ASSIGNMENT];
        const enableShippingMethods = assignment !== ASSIGNMENTS.IN_OFFICE;

        const existingFieldsMap = {
          [ATTRIBUTES.FIRST_NAME]: (
            <GridField xs={12} sm={6} key={ATTRIBUTES.FIRST_NAME} name={ATTRIBUTES.FIRST_NAME}>
              <Field
                variant="outlined"
                fullWidth
                required
                id="first_name"
                label="Patient First Name"
                name="first_name"
                component={NameField}
              />
            </GridField>
          ),

          [ATTRIBUTES.LAST_NAME]: (
            <GridField key={ATTRIBUTES.LAST_NAME} name={ATTRIBUTES.LAST_NAME} xs={12} sm={6}>
              <Field
                name="last_name"
                variant="outlined"
                required
                fullWidth
                id="last_name"
                label="Patient Last Name"
                component={NameField}
              />
            </GridField>
          ),

          [ATTRIBUTES.CASE_NUMBER_TEXT]: (
            <GridField
              key={ATTRIBUTES.CASE_NUMBER_TEXT}
              name={ATTRIBUTES.CASE_NUMBER_TEXT}
              xs={12}
              sm={6}
            >
              <Field
                name="case_number_text"
                variant="outlined"
                fullWidth
                id="case_number_text"
                label="Patient Case Number"
                component={TextField}
              />
            </GridField>
          ),

          [ATTRIBUTES.INTRAORAL_SCAN_DATE]: (
            <GridField
              key={ATTRIBUTES.INTRAORAL_SCAN_DATE}
              name={ATTRIBUTES.INTRAORAL_SCAN_DATE}
              xs={12}
              sm={6}
            >
              <DatePicker label="Intraoral Scan Date" name="intraoral_scan_date" required />
            </GridField>
          ),

          [ATTRIBUTES.DOCTOR]: withDoctorSelection && (
            <GridField key={ATTRIBUTES.DOCTOR} name={ATTRIBUTES.DOCTOR} xs={12} md={6}>
              <DoctorSelectionField
                name={ATTRIBUTES.DOCTOR}
                doctors={doctors.list}
                fetching={doctors.fetching}
                preferenceValue={preference[ATTRIBUTES.DOCTOR]}
                isSubmitting={isSubmitting}
              />
            </GridField>
          ),

          [ATTRIBUTES.TREATMENT_LOCATION]: (
            <GridField
              key={ATTRIBUTES.TREATMENT_LOCATION}
              name={ATTRIBUTES.TREATMENT_LOCATION}
              xs={12}
              sm={withDoctorSelection ? 6 : 12}
            >
              <LocationField
                dependency
                name={ATTRIBUTES.TREATMENT_LOCATION}
                locations={availableLocations}
                isSubmitting={isSubmitting}
              />
            </GridField>
          ),

          [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]: (
            <GridField
              key={ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT}
              name={ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT}
              md={12}
            >
              <>
                <AssignmentField
                  name={ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT}
                  options={preference.initial_case_setup_assignment_options}
                  thirdPartyPartnerName={thirdPartyPartnerName}
                  isSubmitting={isSubmitting}
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.RUSH_CASE]: (
            <GridField
              key={ATTRIBUTES.RUSH_CASE}
              name={ATTRIBUTES.RUSH_CASE}
              xs={12}
              show={
                decodedValues[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT] !== ASSIGNMENTS.IN_OFFICE
              }
            >
              <>
                <RushCaseField
                  name={ATTRIBUTES.RUSH_CASE}
                  preferenceValue={preference[ATTRIBUTES.RUSH_CASE]}
                  dependency={
                    decodedValues[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT] !==
                    ASSIGNMENTS.IN_OFFICE
                  }
                  resetValue={setField => {
                    setField(ATTRIBUTES.RUSH_CASE, '0');
                    setField(ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME, '');
                  }}
                  isSubmitting={isSubmitting}
                  rushCaseFee={
                    get(pricing, 'arch.rush_case', 0) *
                    (decodedValues.jaws_id === ARCHES.BOTH ? 2 : 1)
                  }
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: (
            <>
              <GridField
                key={ATTRIBUTES.SCAN_SUBMISSION_METHOD}
                name={ATTRIBUTES.SCAN_SUBMISSION_METHOD}
                xs={12}
              >
                <>
                  <FormControl component="fieldset">
                    <ScanSubmissionMethodField
                      preferenceValue={preference.scan_submission_options}
                      thirdPartyPartnerName={thirdPartyPartnerName}
                      isSubmitting={isSubmitting}
                    />
                  </FormControl>
                  <Divider />
                </>
              </GridField>

              <GridField
                name={ATTRIBUTES.SCAN_SUBMISSION_METHOD}
                xs={12}
                show={decodedValues.scan_submission_type === ASSIGNMENTS.ORTHOSELECT}
              >
                <>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">
                      Upload intraoral STL files{' '}
                      {saveForLaterEnabled && values[ATTRIBUTES.SAVED_FOR_LATER] ? '' : '*'}
                    </FormLabel>
                    <UploadRxFormFile
                      fileModifier={
                        decodedValues[ATTRIBUTES.ROOT_INTEGRATION] && isManagement
                          ? IntraoralTypeSwitch
                          : null
                      }
                      accept={ACCEPTED_FILE_TYPES[FILE_TYPE_IDS.stl]}
                      dependency={decodedValues.scan_submission_type === ASSIGNMENTS.ORTHOSELECT}
                      name={ATTRIBUTES.STL_FILES}
                      preferenceValue={[]}
                      title="Upload STL Files"
                      onChange={(updatedFiles, filePath) => {
                        let files = autoDetectStlTypes(updatedFiles || []);

                        if (typeof filePath !== 'undefined') {
                          files = produce(files, draft => {
                            const index = draft.findIndex(file => file.file_path === filePath);

                            if (index !== -1) {
                              const updatedFile = draft[index];
                              // set other type to opposite
                              const otherIndex = draft.findIndex(
                                file => file.file_path !== filePath && file.tag
                              );

                              if (otherIndex !== -1 && draft[otherIndex].tag === updatedFile.tag) {
                                draft[otherIndex].tag =
                                  updatedFile.tag === tags.STL_MANDIBULAR
                                    ? tags.STL_MAXILLARY
                                    : tags.STL_MANDIBULAR;
                              }
                            }
                          });
                        }

                        setTimeout(() => setFieldValue('stl_files', files));
                        setFieldTouched('stl_files', true, false);
                      }}
                      values={values.stl_files}
                      label="Add Files"
                      maxFiles={10}
                      organization={organization}
                      autoExtractCompressedFiles={
                        ZIP_EXTRACTABLE_FILE_TYPES[FILE_TYPE_IDS.stl] || null
                      }
                      existingFiles={values.stl_files}
                      preventDuplicate
                    />
                    <ErrorMessage
                      name="stl_files"
                      render={message => <InputErrorMessage>{message}</InputErrorMessage>}
                    />
                  </FormControl>
                  <Divider />
                </>
              </GridField>

              <GridField
                name={ATTRIBUTES.SCANNER}
                xs={12}
                skipOnly
                show={decodedValues[ATTRIBUTES.SCAN_SUBMISSION_METHOD] === 2}
              >
                <>
                  <ScannerField
                    name={ATTRIBUTES.SCANNER}
                    dependency={decodedValues[ATTRIBUTES.SCAN_SUBMISSION_METHOD] === 2}
                    preferenceValue={SCANNERS.ITERO}
                    isSubmitting={isSubmitting}
                  />
                  <Divider />
                </>
              </GridField>
            </>
          ),

          [ATTRIBUTES.INTRAORAL_PHOTOS]: (
            <GridField key={ATTRIBUTES.INTRAORAL_PHOTOS} name={ATTRIBUTES.INTRAORAL_PHOTOS} xs={12}>
              <>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    Upload intraoral photos here (we require at least one intraoral to verify the
                    scans match the patient){' '}
                    {saveForLaterEnabled && values[ATTRIBUTES.SAVED_FOR_LATER] ? '' : '*'}
                  </FormLabel>
                  <UploadRxFormFile
                    accept={ACCEPTED_FILE_TYPES[FILE_TYPE_IDS.photos]}
                    name={ATTRIBUTES.INTRAORAL_PHOTOS}
                    title="Upload Intraoral Photos"
                    onChange={files => {
                      setTimeout(() => setFieldValue('rx_intraoral_photos', files));
                      setFieldTouched('rx_intraoral_photos', true, false);
                    }}
                    values={values.rx_intraoral_photos}
                    label="Add Files"
                    maxFiles={10}
                    organization={organization}
                    preferenceValue={[]}
                    autoExtractCompressedFiles={
                      ZIP_EXTRACTABLE_FILE_TYPES[FILE_TYPE_IDS.photos] || null
                    }
                  />
                  <ErrorMessage
                    name="rx_intraoral_photos"
                    render={message => <InputErrorMessage>{message}</InputErrorMessage>}
                  />
                </FormControl>
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.ARCH_TYPE]: (
            <GridField key={ATTRIBUTES.ARCH_TYPE} name={ATTRIBUTES.ARCH_TYPE} xs={12}>
              <>
                <RadioGroupField isSubmitting={isSubmitting} name={ATTRIBUTES.ARCH_TYPE} />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.EXPORT_TYPE]: (
            <GridField key={ATTRIBUTES.EXPORT_TYPE} name={ATTRIBUTES.EXPORT_TYPE} xs={12}>
              <>
                <ExportTypeField
                  isSubmitting={isSubmitting}
                  name={ATTRIBUTES.EXPORT_TYPE}
                  options={preference.export_type_options}
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.PRINT_ASSIGNMENT]: (
            <GridField key={ATTRIBUTES.PRINT_ASSIGNMENT} name={ATTRIBUTES.PRINT_ASSIGNMENT} xs={12}>
              <>
                <AssignmentField
                  name={ATTRIBUTES.PRINT_ASSIGNMENT}
                  options={preference.print_assignment_options}
                  thirdPartyPartnerName={thirdPartyPartnerName}
                  isSubmitting={isSubmitting}
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT]: (
            <GridField
              key={ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT}
              name={ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT}
              xs={12}
              show={
                decodedValues[ATTRIBUTES.PRINT_ASSIGNMENT] === ASSIGNMENTS.IN_OFFICE &&
                decodedValues[ATTRIBUTES.EXPORT_TYPE] === EXPORTS.TRAYS
              }
            >
              <>
                <TrayDesignAssignmentField
                  name={ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT}
                  options={preference.tray_design_assignment_options}
                  isSubmitting={isSubmitting}
                  preferenceValue={getDefaultPreferenceTrayDesignAssignment(preference)}
                  dependency={
                    decodedValues[ATTRIBUTES.PRINT_ASSIGNMENT] === ASSIGNMENTS.IN_OFFICE &&
                    decodedValues[ATTRIBUTES.EXPORT_TYPE] === EXPORTS.TRAYS
                  }
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: (
            <GridField
              key={ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT}
              name={ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT}
              xs={12}
            >
              <>
                <InsertBracketsAssignmentField
                  currentPrintAssignment={decodedValues.print_assignment}
                  isSubmitting={isSubmitting}
                  preferenceValue={preference[ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]}
                  setDefault={value => {
                    setFieldValue(ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT, String(value));
                  }}
                  thirdPartyPartnerName={thirdPartyPartnerName}
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.BRACKET_LIBRARIES]: (
            <GridField
              key={ATTRIBUTES.BRACKET_LIBRARIES}
              name={ATTRIBUTES.BRACKET_LIBRARIES}
              xs={12}
            >
              <>
                <BracketLibrariesField
                  organizationId={organization.id}
                  doctorId={values.user_id}
                  name={ATTRIBUTES.BRACKET_LIBRARIES}
                  resetValueOnFetch={!rxForm.id}
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.ARCH_WIRES]: (
            <GridField key={ATTRIBUTES.ARCH_WIRES} name={ATTRIBUTES.ARCH_WIRES} xs={12}>
              <>
                <ArchWiresField
                  name={ATTRIBUTES.ARCH_WIRES}
                  organizationId={organization.id}
                  doctorId={values.user_id}
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.SHIPPING_LOCATION]: (
            <GridField
              key={ATTRIBUTES.SHIPPING_LOCATION}
              name={ATTRIBUTES.SHIPPING_LOCATION}
              xs={12}
              md={6}
              show={decodedValues[ATTRIBUTES.PRINT_ASSIGNMENT] !== ASSIGNMENTS.IN_OFFICE}
            >
              <LocationField
                name={ATTRIBUTES.SHIPPING_LOCATION}
                locations={availableLocations}
                dependency={decodedValues[ATTRIBUTES.PRINT_ASSIGNMENT] !== ASSIGNMENTS.IN_OFFICE}
                preferenceValue={preference[ATTRIBUTES.SHIPPING_LOCATION]}
                isSubmitting={isSubmitting}
              />
            </GridField>
          ),

          [ATTRIBUTES.SHIPPING_METHOD]: (
            <GridField
              key={ATTRIBUTES.SHIPPING_METHOD}
              name={ATTRIBUTES.SHIPPING_METHOD}
              xs={12}
              md={6}
              show={enableShippingMethods}
            >
              <ShippingMethodField
                name={ATTRIBUTES.SHIPPING_METHOD}
                dependency={enableShippingMethods}
                preferenceValue={preference[ATTRIBUTES.SHIPPING_METHOD]}
                isSubmitting={isSubmitting}
                resetValue={() => {
                  if (!enableShippingMethods) {
                    setFieldValue(ATTRIBUTES.SHIPPING_METHOD, null);
                    setFieldTouched(ATTRIBUTES.SHIPPING_METHOD);
                  }
                }}
              />
            </GridField>
          ),

          [ATTRIBUTES.ROOT_INTEGRATION]: (
            <GridField key={ATTRIBUTES.ROOT_INTEGRATION} name={ATTRIBUTES.ROOT_INTEGRATION} xs={12}>
              <>
                <RootIntegrationField
                  name={ATTRIBUTES.ROOT_INTEGRATION}
                  dependency={preference[ATTRIBUTES.ROOT_INTEGRATION]}
                  preferenceValue={preference[ATTRIBUTES.ROOT_INTEGRATION]}
                  isSubmitting={isSubmitting}
                  fee={get(pricing, 'root_integration', 0)}
                />
                {decodedValues[ATTRIBUTES.ROOT_INTEGRATION] && (
                  <FormControl component="fieldset" fullWidth>
                    <UploadRxFormFile
                      accept={ACCEPTED_FILE_TYPES[FILE_TYPE_IDS.dicom]}
                      note={UPLOAD_NOTES[FILE_TYPE_IDS.dicom]}
                      name={ATTRIBUTES.DICOM_FILES}
                      title="Upload Dicom Files"
                      onChange={files => {
                        setTimeout(() => setFieldValue(ATTRIBUTES.DICOM_FILES, files));
                        setFieldTouched(ATTRIBUTES.DICOM_FILES, true, false);
                      }}
                      values={values[ATTRIBUTES.DICOM_FILES]}
                      label="Add Files"
                      maxFiles={1}
                      organization={organization}
                      preferenceValue={[]}
                      validateFile={validateDicomUpload}
                    />
                    <ErrorMessage
                      name="dicom_files"
                      render={message => <InputErrorMessage>{message}</InputErrorMessage>}
                    />
                  </FormControl>
                )}
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.BONDING_DATE]: (
            <GridField
              key={ATTRIBUTES.BONDING_DATE}
              name={ATTRIBUTES.BONDING_DATE}
              xs={12}
              sm={6}
              show={decodedValues[ATTRIBUTES.SHIPPING_METHOD] !== 'international_shipping'}
            >
              <>
                <BondingDateField
                  label={`${VIEW_LABEL[ATTRIBUTES.BONDING_DATE]}`}
                  name={ATTRIBUTES.BONDING_DATE}
                  createdAt={rxForm.created_at_mt || ''}
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.NOTES]: (
            <GridField key={ATTRIBUTES.NOTES} name={ATTRIBUTES.NOTES} xs={12}>
              <Field
                variant="outlined"
                fullWidth
                id="special_instructions"
                label={`(Optional) ${QUESTIONS[ATTRIBUTES.NOTES]}`}
                name="special_instructions"
                multiline
                component={TextField}
              />
            </GridField>
          ),

          [ATTRIBUTES.CUSTOM_SETUP]: (
            <GridField key={ATTRIBUTES.CUSTOM_SETUP} name={ATTRIBUTES.CUSTOM_SETUP} xs={12}>
              <>
                <CustomSetupField
                  name={ATTRIBUTES.CUSTOM_SETUP}
                  dependency
                  resetValue={setField => {
                    setField(ATTRIBUTES.CUSTOM_SETUP, '0');
                  }}
                  isSubmitting={isSubmitting}
                />
                <Divider />
              </>
            </GridField>
          ),

          [ATTRIBUTES.TEETH]: (
            <GridField key={ATTRIBUTES.TEETH} name={ATTRIBUTES.TEETH} xs={12}>
              <>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    <ol style={{ paddingLeft: '20px' }}>
                      <li>Click any teeth that are missing or will be extracted (Blue)</li>
                      <li>Click again to keep the space open (Green)</li>
                    </ol>
                  </FormLabel>
                  <Teeth
                    data={values.teeth || {}}
                    onChange={teeth => {
                      setFieldValue(ATTRIBUTES.TEETH, teeth);
                      setFieldTouched(ATTRIBUTES.TEETH, true, false);
                    }}
                  />
                  <ErrorMessage
                    name={ATTRIBUTES.TEETH}
                    render={message => <InputErrorMessage>{message}</InputErrorMessage>}
                  />
                </FormControl>
                <Divider />
              </>
            </GridField>
          )
        };

        return (
          <Card {...props}>
            <CardHeader title={title} subheader={description} />
            <CardContent>
              <Context.Provider value={{ only, hiddenFields }}>
                <Form className={classes.form} noValidate>
                  {formStructure.map(section => {
                    if (section.system_type === LAYOUT_DIVIDER) {
                      return <Divider className={classes.divider} />;
                    }

                    const colSize = 12 / section.children.length;

                    if (!hasUpdatableFields(section, only)) {
                      return '';
                    }
                    return (
                      <Grid
                        container
                        spacing={1}
                        key={`section-${section.id}`}
                        className={classes.section}
                      >
                        {section.children.map(col => {
                          const itemKey = `column-${col.id}`;
                          return (
                            <Grid item xs={12} key={itemKey} md={colSize}>
                              {col.children.map(field => {
                                const comp = existingFieldsMap[field.system_name];
                                const shouldRenderCustomField =
                                  newControls.findIndex(
                                    control => control.system_name === field.system_name
                                  ) !== -1;

                                if (!comp && !shouldRenderCustomField) {
                                  return '';
                                }

                                return (
                                  comp ||
                                  renderFormBuilderField({
                                    ...field,
                                    decodedValues,
                                    setFieldValue,
                                    setFieldTouched,
                                    values
                                  })
                                );
                              })}
                            </Grid>
                          );
                        })}
                      </Grid>
                    );
                  })}
                </Form>
              </Context.Provider>
            </CardContent>
            <Divider />
            <CardActions style={{ justifyContent: 'flex-end' }}>
              {saveForLaterEnabled && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values[ATTRIBUTES.SAVED_FOR_LATER]}
                      onChange={event => {
                        setFieldValue(ATTRIBUTES.SAVED_FOR_LATER, event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label="Save for later"
                />
              )}
              {actions({ dirty, status, isValid, submitForm, setFieldValue, values })}
            </CardActions>
          </Card>
        );
      }}
    </Formik>
  );
};

RxForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  availableLocations: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  preference: PropTypes.object.isRequired,
  thirdPartyPartnerName: PropTypes.string,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.any.isRequired,
  only: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pricing: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  newControls: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  formStructure: PropTypes.array,
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  saveForLaterEnabled: PropTypes.bool,
  doctors: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        full_name: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired
      })
    ).isRequired,
    fetching: PropTypes.bool.isRequired
  })
};

RxForm.defaultProps = {
  rxForm: null,
  availableLocations: [],
  thirdPartyPartnerName: null,
  only: [],
  doctors: {
    enabled: false,
    list: [],
    fetching: false
  },
  pricing: null,
  hiddenFields: [],
  saveForLaterEnabled: false
};
/**
 * No longer used
 * - isDeleting
 * - onDelete
 *
 */
export default RxForm;
