import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputIcon from '@material-ui/icons/Input';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../../reducers/auth';
import logo from '../../../assets/images/logo-2024.png';
import OrganizationSelector from '../../OrganizationSelector';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none'
  },
  logo: {
    height: '45px'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Basic = props => {
  const { children } = props;
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <img alt="Logo" src={logo} className={classes.logo} />
          <div className={classes.flexGrow} />
          <OrganizationSelector />
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            title="Log Out"
            onClick={() => dispatch(logoutAction())}
          >
            <InputIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {children}
    </>
  );
};

Basic.propTypes = {
  children: PropTypes.node.isRequired
};

export default Basic;
