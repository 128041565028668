import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Container, CssBaseline, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dibsLogo from '../../assets/images/logo-2024.png';
import { getToken } from '../../helpers/connection';
import { createDesktopURI } from '../../helpers/urls';

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    margin: theme.spacing(1),
    width: 220
  },
  message: {
    marginTop: 25,
    textAlign: 'center'
  },
  error: {
    marginTop: 25,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#d9363e',
    fontSize: '24px'
  },
  bold: {
    fontWeight: 'bold'
  },
  button: {
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(8)
  }
}));

const RedirectToDesktopApp = ({ location }) => {
  const classes = useStyles();
  const [token, setToken] = useState(null);
  const [isValidated, setIsValidated] = useState(false);
  const [error, setError] = useState(null);

  const extractParamFromQuery = useCallback(
    paramToExtract => {
      const searchQuery = _.get(location, ['search'], null);

      if (_.isEmpty(searchQuery) || _.isNil(searchQuery)) return null;

      const params = new URLSearchParams(searchQuery);

      return params.get(paramToExtract);
    },
    [location]
  );

  /**
   * Redirect to the desktop app
   */
  const handleOpenPatientOnDibs = useCallback(() => {
    const targetUrl = extractParamFromQuery('q');

    if (_.isNil(token) || _.isNil(targetUrl)) return;

    window.location.href = createDesktopURI(targetUrl);
  }, [extractParamFromQuery, token]);

  /**
   * Fetch the desktop app name (will be displayed on the browser dialog)
   */
  const getAppName = () => process.env.REACT_APP_DESKTOP_APP_NAME || 'DIBS AI';

  useEffect(() => {
    // Check if user already logged in
    // If so, then use the app access_token instead of the URL access_token (avoid expired email tokens)
    const accessToken = getToken() || extractParamFromQuery('token');

    const handler = Axios.get(`${process.env.REACT_APP_API_URL}/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then(() => setToken(accessToken))
      .catch(() => setError('This link has expired!'))
      .finally(() => setIsValidated(true));

    return () => handler.cancel();
  }, [extractParamFromQuery]);

  useEffect(() => {
    handleOpenPatientOnDibs();
  }, [handleOpenPatientOnDibs]);

  if (!isValidated) return null;

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={dibsLogo} alt="DIBS AI Logo" />

        {error && <Typography className={classes.error}>{error}</Typography>}

        {!error && (
          <>
            <Typography className={classes.message}>
              Click <span className={classes.bold}>Open {getAppName()}</span> on the dialog shown by
              your browser. If you don’t see a dialog, click{' '}
              <span className={classes.bold}>Open DIBS AI</span> button below.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleOpenPatientOnDibs}
            >
              Open DIBS AI
            </Button>
          </>
        )}
      </div>
    </Container>
  );
};

RedirectToDesktopApp.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

export default RedirectToDesktopApp;
