import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import LoadingButton from '../../../../components/LoadingButton';
import SubSectionWrapper from '../SubSectionWrapper';
import PreviewForm from './PreviewForm';
import FormBuilder from './FormBuilder';
import { MainContext } from './contexts';
import { fetchFormBuilderAction } from '../../../../reducers/formBuilder';
import OrganizationsApiService from '../../../../services/api/organizations';
import LoadingIndicator from '../../../../components/LoadingIndicator';

const RxFormCustomization = ({ organization }) => {
  const dispatch = useDispatch();
  const [openFormBuilder, setOpenFormBuilder] = useState(false);
  const formBuilder = useSelector(state => state.formBuilder.form);
  const defaultControls = useSelector(state => state.formBuilder.defaultControls);
  const layout = useSelector(state => state.formBuilder.layout);
  const [hiddenFields, setHiddenFields] = useState([]);
  const organizationId = get(organization, 'id', null);
  const mounted = useRef();

  const openForFormBuilder = () => {
    setOpenFormBuilder(true);
  };

  useEffect(() => {
    const params = { organization_id: organizationId };
    dispatch(fetchFormBuilderAction(params));
  }, [dispatch, organizationId]);

  useEffect(() => {
    mounted.current = true;
    const organizationApiService = new OrganizationsApiService();
    organizationApiService.getRxFormPreference(organizationId).then(({ data, metadata }) => {
      if (!mounted.current) {
        return;
      }
      setHiddenFields(metadata.hidden_fields);
    });

    return () => {
      mounted.current = false;
    };
  }, [organizationId]);

  if (!formBuilder) {
    return <LoadingIndicator />;
  }

  return (
    <SubSectionWrapper
      title="Customize RxForm"
      subTitle={formBuilder.type === 'custom' ? 'Custom RxForm' : formBuilder.name}
    >
      <MainContext.Provider value={{ formBuilder, hiddenFields }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end">
              <LoadingButton
                type="submit"
                variant="outlined"
                color="primary"
                loading={false}
                style={{ marginRight: 10 }}
                onClick={openForFormBuilder}
              >
                Edit RxForm
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <PreviewForm layout={layout} />
          </Grid>
        </Grid>

        <FormBuilder
          hiddenField={hiddenFields}
          open={openFormBuilder}
          organization={organization}
          formBuilder={formBuilder}
          layout={layout}
          defaultControls={defaultControls}
          onClose={() => setOpenFormBuilder(false)}
        />
      </MainContext.Provider>
    </SubSectionWrapper>
  );
};

RxFormCustomization.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object
};

export default RxFormCustomization;
