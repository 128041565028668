import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import SettingIcon from '@material-ui/icons/Settings';
import HandleIcon from '@material-ui/icons/DragHandle';
import PropTypes from 'prop-types';
import { CONTROL_EXISTING, TYPE_CONTROL, TYPE_RENDERED_CONTROL } from '../helpers';

const FormControl = ({
  id,
  system_type,
  columnId,
  sectionId,
  isPreview,
  showSettings,
  removeControl,
  moveControl,
  children,
  isNew
}) => {
  const itemData = {
    id,
    columnId,
    sectionId,
    system_type,
    action: 'move',
    isNew
  };

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: TYPE_RENDERED_CONTROL,
    item: itemData,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  const [{ isOver }, drop] = useDrop({
    accept: [TYPE_RENDERED_CONTROL, TYPE_CONTROL],
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }

      if (moveControl) {
        moveControl({
          src: {
            itemId: item.id,
            columnId: item.columnId,
            sectionId: item.sectionId,
            system_type: item.system_type,
            isNew: item.isNew
          },
          dst: {
            itemId: id,
            columnId,
            sectionId
          }
        });
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  });

  const style = {};
  const classNames = ['fb-form-control'];

  if (system_type !== CONTROL_EXISTING) {
    classNames.push('fb-form-custom-field');
  }

  if (isDragging) {
    style.opacity = '0.7';
  } else {
    style.opacity = '1';
  }

  if (isOver) {
    classNames.push('highlight');
  }

  return (
    <div ref={node => preview(drop(node))} className={classNames.join(' ')} style={style}>
      {children}

      {!isPreview && (
        <div className="fb-button-settings-wrapper">
          {system_type !== CONTROL_EXISTING && (
            <button type="button" className="fb-fc-settings" onClick={showSettings}>
              <SettingIcon />
            </button>
          )}

          <button ref={drag} type="button" className="fb-fc-drag-handle">
            <HandleIcon />
          </button>
        </div>
      )}
    </div>
  );
};

FormControl.propTypes = {
  isPreview: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  showSettings: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

FormControl.defaultProps = {
  isPreview: false
};

export default FormControl;
