import React from 'react';
import { CardActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import View from '../../../components/RxForm/View/index';
import RxFormProvider from '../../../components/RxForm/RxFormProvider';
import { useRxForm } from '../../../components/RxForm/hooks';
import PatientModificationWarning from '../../../components/PatientModificationWarning';
import { isCompletedPatient } from '../../../helpers';
import { openPatientModificationWarning } from '../../../reducers/modals';

const RxFormInfo = ({ rxForm, modifiable, additionalButtons, redirectToModifyPage, ...props }) => {
  return (
    <Card {...props}>
      <CardHeader title="RxForm" />
      <Divider />
      <CardContent>
        <View rxForm={rxForm} />
      </CardContent>
      <Divider />
      <CardActions>
        {additionalButtons()}
        {modifiable && (
          <Button
            variant="contained"
            color="primary"
            title="Modify"
            style={{ marginLeft: 'auto' }}
            onClick={redirectToModifyPage}
          >
            Edit
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

RxFormInfo.defaultProps = {
  additionalButtons: ''
};

RxFormInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object.isRequired,
  modifiable: PropTypes.bool.isRequired,
  additionalButtons: PropTypes.func,
  redirectToModifyPage: PropTypes.func.isRequired
};

const WithRxForm = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { rxForm, modificationRules } = useRxForm();

  const { patient } = props;

  const onPatientModificationWarningClose = answer => {
    if (answer) {
      history.push(`/rx-forms/${rxForm.id}/modify`);
    }
  };

  return (
    <>
      <RxFormInfo
        rxForm={rxForm}
        modifiable={Object.keys(modificationRules).length > 0}
        redirectToModifyPage={() => {
          if (isCompletedPatient(patient)) {
            dispatch(openPatientModificationWarning('Patients-RxFormInfo'));
            return;
          }
          history.push(`/rx-forms/${rxForm.id}/modify`);
        }}
        {...props}
      />

      <PatientModificationWarning
        id="Patients-RxFormInfo"
        onClose={onPatientModificationWarningClose}
      />
    </>
  );
};

WithRxForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired
};

const Wrapper = ({ id, ...props }) => {
  return (
    <RxFormProvider id={id} withPreference>
      <WithRxForm {...props} />
    </RxFormProvider>
  );
};

Wrapper.propTypes = {
  id: PropTypes.number.isRequired
};

export default Wrapper;
