import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import _get from 'lodash/get';
import { HELD_BY_GROUPS } from '../../constants/patients';

const PatientOnHoldChip = ({ patient, size, ...rest }) => {
  const getChipColor = () => {
    if (!patient.is_on_hold) {
      return 'primary';
    }

    const heldByGroup = _get(patient, 'held_by_group');

    if (heldByGroup) {
      return heldByGroup === HELD_BY_GROUPS.DOCTORS ? 'primary' : 'secondary';
    }

    return 'primary';
  };

  if (!patient || !patient.held_at) {
    return null;
  }

  return <Chip color={getChipColor()} size={size} label="On Hold" {...rest} />;
};

PatientOnHoldChip.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.any,
  size: PropTypes.string
};

PatientOnHoldChip.defaultProps = {
  patient: null,
  size: 'small'
};

export default PatientOnHoldChip;
