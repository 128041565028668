import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';

const hasTrayFiles = patient => {
  if (!patient.patient_files) {
    return false;
  }
  return (
    patient.patient_files.filter(file => /.*(UL|UR|UC|LL|LR|LC)\.stl$/.test(file.original_name))
      .length > 0
  );
};

const initialState = {
  patientId: null,
  validationMessage: null,
  canProceed: false,
  dialogOpen: false,
  successCallback: null
};

export default ({ patient, children }) => {
  const [state, setState] = useState({
    ...initialState,
    patientId: patient.id
  });

  const resetState = useCallback(
    (override = {}) => {
      setState({
        ...initialState,
        patientId: patient.id,
        ...override
      });
    },
    [patient]
  );

  useEffect(() => {
    if (patient && state.patientId !== patient.id) {
      resetState({ patientId: patient.id });
    }
  }, [patient, resetState, state]);

  const validate = async (workflowStep, onSuccess) => {
    if (workflowStep.process !== 'Generate QA') {
      return onSuccess();
    }

    const canProceed = hasTrayFiles(patient);

    setState(curent => ({
      ...curent,
      validationTitle: canProceed ? 'Are you sure?' : 'Attention:',
      validationMessage: canProceed
        ? 'Have all the tray STL files been uploaded?'
        : 'Please upload the individual tray STL files before proceeding',
      dialogOpen: true,
      canProceed,
      successCallback: onSuccess
    }));

    return Promise.resolve();
  };

  const onClose = async reason => {
    if (reason === 'proceed') {
      await state.successCallback();
    }

    resetState();

    return Promise.resolve();
  };

  const ValidationDialog = () => {
    if (state.validationMessage === null) {
      return '';
    }

    return (
      <Dialog onClose={onClose} open={state.dialogOpen}>
        {state.dialogOpen && (
          <>
            <DialogTitle>Attention:</DialogTitle>
            <DialogContent>
              <DialogContentText>{state.validationMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              {state.canProceed && (
                <>
                  <Button variant="contained" color="secondary" onClick={() => onClose('cancel')}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => onClose('proceed')}>
                    Confirm
                  </Button>
                </>
              )}
              {!state.canProceed && (
                <Button variant="contained" color="secondary" onClick={() => onClose('cancel')}>
                  Close
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  };

  return children({ validate, ValidationDialog });
};
