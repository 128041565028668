import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/DeleteRounded';
import { useDrag } from 'react-dnd';
import HandleIcon from '@material-ui/icons/DragHandle';
import { EVENT_DELETE_LAYOUT_ELEMENT, TYPE_LAYOUT } from '../helpers';
import { ControlContext } from '../../contexts';

export default function Divider({ element, isPreview }) {
  const { onDelete } = useContext(ControlContext);

  // make section draggable
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: TYPE_LAYOUT,
    item: { id: element.id },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  const style = {};

  if (isDragging) {
    style.opacity = '0.7';
  } else {
    style.opacity = '1';
  }

  return (
    <div ref={node => preview(node)} className="fb-element-divider" style={style}>
      {!isPreview && (
        <IconButton
          variant="contained"
          className="delete-divider"
          onClick={() =>
            onDelete({
              id: element.id,
              event: EVENT_DELETE_LAYOUT_ELEMENT
            })
          }
          size="small"
          title="Delete Section"
        >
          <TrashIcon color="error" />
        </IconButton>
      )}

      <div className="fbe-divider" />

      {!isPreview && (
        <button ref={drag} type="button" className="fb-div-drag-handle">
          <HandleIcon />
        </button>
      )}
    </div>
  );
}
