import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ColorPicker } from 'material-ui-color';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import Chip from '@material-ui/core/Chip';
import { CONTROL_FILE, fieldHasOptions } from '../../helpers';

const useStyles = makeStyles(() => ({
  symbol: {
    width: 64
  }
}));

const MarkerSettings = ({ symbol, color, onChange }) => {
  const classes = useStyles();

  const reset = () => {
    onChange(null);
  };

  const triggerOnChange = (name, value) => {
    const updated = {
      symbol,
      color,
      [name]: value
    };
    if (Object.keys(updated).filter(key => !updated[key]).length === 2) {
      onChange(null);
      return;
    }
    onChange(updated);
  };

  const handleChange = event => {
    triggerOnChange(event.target.name, event.target.value);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <TextField
          label="Symbol"
          variant="outlined"
          name="symbol"
          size="small"
          value={symbol || ''}
          inputProps={{
            maxLength: 1,
            className: classes.symbol
          }}
          onChange={handleChange}
        />
      </Box>
      <Box display="flex">
        <TextField
          type="text"
          id="marker-color"
          variant="outlined"
          name="color"
          size="small"
          label="Color"
          value={color || ''}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
            maxLength: 6,
            minLength: 6,
            startAdornment: <InputAdornment position="start">#</InputAdornment>
          }}
        />

        <ColorPicker
          value={`#${color}`}
          hideTextfield
          onChange={value => triggerOnChange('color', value.hex)}
        />
      </Box>
      <Box>
        <Button variant="contained" color="secondary" onClick={reset}>
          Reset
        </Button>
      </Box>
    </Box>
  );
};

MarkerSettings.propTypes = {
  symbol: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

MarkerSettings.defaultProps = {
  symbol: null,
  color: null
};

const isAppliedToAll = options => {
  if (options.length < 2) {
    return false;
  }

  if (
    options.filter(option => option.marker && (option.marker.symbol || option.marker.color))
      .length !== options.length
  ) {
    return false;
  }

  const match = options[0].marker || {};

  return (
    options.filter(option => {
      const { marker } = option;
      return match.symbol === marker.symbol && marker.color === match.color;
    }).length === options.length
  );
};

const FieldOptionMarkerSettings = ({ options, onUpdate }) => {
  const [current, setCurrent] = useState(options.length > 0 ? options[0].id : null);
  if (!current) {
    return 'No options available';
  }

  const appliedToAll = isAppliedToAll(options);
  const handleOptionSelection = event => {
    setCurrent(event.target.value);
  };

  const handleChange = applyToAll => marker => {
    const updatedOptions = options.map(option => {
      if (applyToAll || option.id === current) {
        return {
          ...option,
          marker
        };
      }

      return option;
    });

    onUpdate(updatedOptions);
  };

  const currentOption = options.find(opt => opt.id === current);

  const handleApplyToAllChange = event => {
    const marker = event.target.checked ? currentOption.marker : null;

    handleChange(true)(marker);
  };

  const hasConfig =
    currentOption.marker && (currentOption.marker.symbol || currentOption.marker.color);

  return (
    <Grid container spacing={2}>
      {!appliedToAll && (
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="marker-option-selection">Select Option</InputLabel>
            <Select
              variant="outlined"
              value={currentOption.id}
              labelId="marker-option-selection"
              onChange={handleOptionSelection}
              fullWidth
            >
              {options.map(option => {
                const symbol = get(option, 'marker.symbol');
                return (
                  <MenuItem value={option.id} key={option.id}>
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <span>{option.name}</span>
                      {!!symbol && (
                        <Chip
                          size="small"
                          label={symbol}
                          style={{ backgroundColor: `#${get(option, 'marker.color')}` }}
                        />
                      )}
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <MarkerSettings
          color={get(currentOption, 'marker.color')}
          symbol={get(currentOption, 'marker.symbol')}
          onChange={handleChange(appliedToAll)}
        />
      </Grid>
      {hasConfig && (
        <Grid item xs={12}>
          <FormControlLabel
            name="apply-to-all"
            onChange={handleApplyToAllChange}
            control={<Checkbox checked={appliedToAll} color="primary" />}
            label="Apply to All"
            color="primary"
          />
        </Grid>
      )}
    </Grid>
  );
};

FieldOptionMarkerSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired
};

const EditMarker = ({ elementType, form, setForm }) => {
  if (elementType === CONTROL_FILE) {
    return 'Markers is currently not available for file upload.';
  }

  if (fieldHasOptions(elementType)) {
    const handleOptionsChange = updatedOptions => {
      setForm({
        ...form,
        props: {
          ...form.props,
          options: updatedOptions
        }
      });
    };

    return (
      <FieldOptionMarkerSettings
        options={get(form, 'props.options', [])}
        onUpdate={handleOptionsChange}
      />
    );
  }

  const handleChange = updatedValue => {
    setForm({
      ...form,
      marker: updatedValue
    });
  };

  return (
    <MarkerSettings
      color={get(form, 'marker.color')}
      symbol={get(form, 'marker.symbol')}
      onChange={handleChange}
    />
  );
};

EditMarker.propTypes = {
  elementType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired
};

export default EditMarker;
